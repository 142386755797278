import styled from "styled-components"

export const Container = styled.div`
    .switch {
        position: relative;
        display: inline-block;
        width: 74px;
        height: 33px;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: 0.4s;

            &::before {
                position: absolute;
                content: '';
                height: 25px;
                width: 25px;
                left: 4px;
                bottom: 4px;

                background-color: white;
                transition: 0.4s;
            }
        }

        input:checked + .slider::before {
            transform: translateX(160%);
        }

        input:checked + .slider {
            background: #1D3C85;
        }

        .slider.rounded {
            border-radius: 20px;
        }

        .slider.rounded::before {
            border-radius: 50%;
        }
    }
`