import styled, { css } from 'styled-components';

interface Props {
    isVisible: boolean;
}

export const Container = styled.div<Props>`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 21;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    background: white;

    opacity: 0;
    pointer-events: none;
    transform: translateY(50px);
    transition: .4s;

    .header-items {
        width: 100%;

        padding: 1rem 2rem 1rem 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .logo {
            width: 15rem;
            cursor: pointer;
        }
    }

    .menu-items {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 60px;

        .menu-item {
            text-decoration: none;
            color: #394A64;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 120%;

            letter-spacing: -0.01em;

            color: #939393;
        }
    }

    .footer-items {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 1rem;
        .contact-us {
            width: 177px;

            display: flex;
            align-items: center;
            gap: 12px;
            
            padding: 13px 24px;

            background: #1D3C85;
            border-radius: 8px;
            border: none;

            .whatsapp-icon {
                width: 20px;
            }

            span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */
                color: #FFFFFF;
            }
        }
    }

    ${({ isVisible }) => isVisible && css`
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0px);
    `}
`