import { Container } from "./styles";
import cx from "classnames";

interface SwitchProps {
    rounded: boolean;
    isToggled: boolean,
    onToggle: () => void;
}

export function Switch({ rounded = false, isToggled, onToggle }: SwitchProps) {
    const sliderCx = cx('slider', {
        'rounded': rounded
    })
    return (
        <Container>
            <label className="switch">
                <input type="checkbox" checked={isToggled} onChange={onToggle}/>
                <span className={sliderCx}></span>
            </label>
        </Container>
    )
}