import { FormEvent, useCallback } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { infoNotify, promiseNotify, warningNotify } from '../../services/notifications';
import { api } from '../../services/api';
import { Container } from './styles';

import Logo10i9 from '../../assets/images/global/10i9-logo.png';
import Whatsapp from "../../assets/images/global/akar-send.png";

export function Footer() {

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {

        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        const token = await executeRecaptcha('yourAction');

        return token;

    }, [executeRecaptcha]);

    const handleKeyUp = useCallback((e: FormEvent<HTMLInputElement>) => {
        e.currentTarget.maxLength = 14;
        let value = e.currentTarget.value;
        
        value = value.replace(/\D/g, "");
        value = value.replace(/^(\d{2})(\d)/, '($1) $2'); 
        
        e.currentTarget.value = value;
    }, []);

    const formik = useFormik({
        initialValues: { city: '', name: '', mailer: '', message: '', subject: 'Contato - 10i9 NFe' },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(50, 'Deve ter 50 caracteres ou menos')
                .min(2, 'Deve ter pelo menos 2 caracteres')
                .required('Este campo é obrigatório'),
            city: Yup.string()
                .required('Este campo é obrigatório'),
            mailer: Yup.string()
                .min(13, 'Deve ter pelo menos 13 caracteres')
                .required('Este campo é obrigatório'),

            message: Yup.string()
                .max(250, 'Deve ter 250 caracteres ou menos')
                .required('Este campo é obrigatório'),
        }),

        onSubmit: values => {
            formik.validateForm();
            handleReCaptchaVerify()
            .then((res) => {

                if (res && res !== undefined && res !== null) {

                    infoNotify('Você não é um robo 🤖!');

                    setTimeout(() => {
                        promiseNotify(
                            api.post('sendmail', values),
                            'Enviando e-mail...',
                            'E-mail enviado com sucesso !',
                            'Envio de e-mail falhou !'
                        );

                        formik.resetForm();
                    }, 6000);

                }
            }).catch((err) => warningNotify('reCaptcha inválido.'));
        },
    });

    return (
        <Container>
            <div className="container">
                <div className='left-content'>
                    <h1 className='title'>Desenvolvido pela 10i9</h1>
                    <span className='phrase'>Formada por profissionais com mais de 20 anos de experiência. A 10i9 é uma empresa criada para conectar tecnologia com negócio.</span>
                    <span className='phrase'>Desenvolvemos soluções inovadoras utilizando tecnologias emergentes para criar projetos de grande impacto e alto valor agregado.</span>
                    <span className='access-here'>Acesse:<a href='https://10i9.com.br' target="_blank" rel="noreferrer" className='highlighted-text'>10i9.com.br</a></span>
                    <img className='logo' src={Logo10i9} alt="logo"/>
                </div>
                <div className='right-content'>
                    <span className='title'>Get in Touch</span>
                    <form className='right-content' onSubmit={formik.handleSubmit}>
                        <input
                            className='form-field'
                            type="text"
                            placeholder='Digite seu nome'
                            id='name'
                            name='name'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            aria-label='Name'
                        />
                        <div className="split-field">
                            <div className="form-group">
                                <input 
                                    className='form-field'
                                    type="text" 
                                    placeholder='Cidade/Estado'
                                    id='city'
                                    name='city'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.city}
                                    aria-label='City'
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    className='form-field'
                                    type="text"
                                    placeholder='DDD + Telefone'
                                    id='mailer'
                                    name='mailer'
                                    onKeyUp={handleKeyUp}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.mailer}
                                    aria-label='Mailer'
                                />
                            </div>
                        </div>
                        <textarea
                            className='textarea-field'
                            placeholder='Digite sua mensagem'
                            id='message'
                            name='message'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.message}
                            aria-label='Message'
                        />
                        <div className="split-area">
                            <div className="required-field">
                                <span className='required-field-text' style={{display: !formik.isValid ? 'block' : 'none'}}>Todos os campos são obrigatórios!</span>
                            </div>
                            <button className='contact-us' type='submit'>
                                <img className='arrow-right' src={Whatsapp} alt="arrow right"/>
                                <span>Enviar mensagem</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Container>
    );
}