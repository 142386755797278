import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    max-width: 461px;

    .list-item {
        display: flex;
        align-items: flex-start;
        gap: 17.12px;

        width: 100%;
        max-width: 399px;
            
        .bookmark-check {
            width: 25px;
            height: 25px;
        }
        .text {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;


            color: #1D3C85;
        }

        & + .list-item {
            margin-top: 15px;
        }
    }
`