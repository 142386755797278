import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root {
        --background: #FFFFFF;
        --comming-soon-title: #2C3381;
        --comming-soon-text-body: #787878;
    }
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        @media (max-width: 1080px) {
            font-size: 93.75%;
        }

        @media (max-width: 720px) {
            font-size: 87.5%;
        }

        scroll-behavior: smooth;
    }

    body {
        background: var(--background);
        -webkit-font-smoothing: antialiased;
    }

    body, input, textarea, button {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 600;
    }
    button {
        cursor: pointer;
    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }

    .send-email-modal-overlay {
        background: rgba(0, 0, 0, 0.68);
        backdrop-filter: blur(15px);

        position: fixed;
        z-index: 31;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .send-email-modal-content {
        width: 100%;
        max-width: 740px;

        position: relative;

        background: #FFFFFF;
        border-radius: 20px;
    }

    .send-email-modal-close {
        position: absolute;
        top: 32.89px;
        right: 34.89px;

        cursor: pointer;
        
        transition: filter .2s;
        &:hover {
            filter: brightness(0.8);
        }
    }
`