import { useToggle } from "../../../shared/hooks/useToggle";
import { PricesCards } from "./components/PricesCards";
import { Switch } from "./components/Switch";
import { pricesItems } from "./data/pricesItem";
import { Container } from "./styles";

export function Prices() {

    const { toggled, setToggled } = useToggle();

    return (
        <Container id="prices">
            <div className="wrapper">
                <h3 className="subtitle">Preço Acessível</h3>
                <h2 className="title">30 dias Grátis</h2>
                <p className="phrase">Um dos sistema mais acessíveis do mercado para você ter controle total de suas notas recebidas.</p>
                <div className="period-content">
                    <span>Mensal</span>
                    <Switch rounded={true} isToggled={toggled} onToggle={() => setToggled(!toggled)}/>
                    <span>Anual</span>
                </div>
                <PricesCards pricesItems={pricesItems} isToggled={toggled}/>
            </div>
        </Container>
    )
}