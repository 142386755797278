import { Container } from "./styles";

import { PricesItemDto } from "../../../../../shared/interfaces/prices-item-dto";
import { ListItem } from "../ListItem";
import { firebaseLogEvent } from "../../../../../services/firebase/firebaseLogEvent";

import Whatsapp from "../../../../../assets/images/social-media/whatsapp.png";
import { useOpenCloseModal } from "../../../../../shared/hooks/useOpenCloseModal";

interface FeaturesCardProps {
    pricesItems: PricesItemDto[];
    isToggled: boolean;
}

export function PricesCards({ pricesItems, isToggled }: FeaturesCardProps) {
    const { handleOpenSendEmailModal, setPlan } = useOpenCloseModal();

    const onOpenWhatsApp = (plan: string) => {
        firebaseLogEvent({
            fbEvent: `click_whatsapp_plan_${plan.toLowerCase()}`,
            fbParams: { plan }
        });
        if( plan.toLowerCase() === 'basic' || plan.toLowerCase() === 'enterprise' ) {
            setPlan(plan);
            handleOpenSendEmailModal();
        } else {
            return window.open(`http://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_PHONE_NUMBER}`, '_blank');
        }
    };

    return (
        <Container className="">
            <div className="prices-content">
                {pricesItems.map((item, index) => {
                    return (
                        <div className={`prices-item ${index === 1 ? 'prices-item-important' : null}`} key={'prices-item' + index}>
                            <div className="plan-type">
                                <img src={item.image} alt={`feature ${index}`} className="prices-item-image" key={'prices-item-image' + index} />
                                <div className="plan-type-texts">
                                    <span className="company">{item.company}</span>
                                    <span className="plan">{item.plan}</span>
                                </div>
                            </div>
                            <span className="description">{item.description}</span>
                            
                            <ListItem texts={isToggled ? item.includedAnnual : item.includedMonthly}></ListItem>
                            <div className="price-content" >
                                <span 
                                    className="price" 
                                    style={
                                            {textAlign: item.yearlyPrice === 'Consulte!' ? 'center' : 'left'}
                                        }
                                >
                                    {`${isToggled ? item.yearlyPrice : item.monthlyPrice}`}
                                    <span 
                                        className="period"
                                        style={
                                            {
                                                display: item.yearlyPrice === 'Consulte!' ? 'none' : 'block',
                                                right: item.plan.toLowerCase() === 'basic' ? '30px' : '8px'
                                            }
                                        }
                                    >/mensais</span>
                                </span>
                            </div>
                            <button className="sign-btn" onClick={ () => onOpenWhatsApp(item.plan) }>
                                <img 
                                    className='whatsapp-icon'
                                    src={Whatsapp}
                                    alt="whatsapp"
                                    style={{display: item.plan.toLowerCase() === 'contabilidade' ? 'block' : 'none'}}/>
                                {item.buttonName}
                            </button>
                        </div>
                    )
                })}
            </div>
        </Container>
    )
}