import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";

import { Container } from "./styles";

import Logo from '../../assets/images/global/if_logo.png';
import Whatsapp from "../../assets/images/social-media/whatsapp.png"
import { firebaseLogEvent } from "../../services/firebase/firebaseLogEvent";

interface MenuMobileProps {
    menuIsVisible: boolean;
    setMenuIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
export function MenuMobile( { menuIsVisible, setMenuIsVisible }: MenuMobileProps ) {

    const navigate = useNavigate();

    const onOpenWhatsApp = () => {
        firebaseLogEvent({fbEvent: 'click_whatsapp_menu_mobile'});
        return window.open(`http://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_PHONE_NUMBER}`, '_blank');
    };

    const navigatgeTo = (route: string) => {
        window.location.href = route;
        setMenuIsVisible(false);
    };

    useEffect(() => { 
        document.body.style.overflowY = menuIsVisible ? 'hidden' : 'auto'; 
        document.body.style.touchAction = menuIsVisible ? 'none' : 'auto';
        if (menuIsVisible) {
            document.body.addEventListener("touchmove",(e) => {e.preventDefault()}, false); 
            document.body.addEventListener("scroll",(e) => {
                e.preventDefault()
                window.scroll(0,0) 
            }, false); 
        }
    }, [menuIsVisible])

    return (
        <Container isVisible={menuIsVisible} >
            <div className="header-items">
                <div className="logo-content">
                    <img className='logo' src={Logo} alt="logo" onClick={() => navigate('/')}/>
                </div>
                <IoClose size={30} color="#181E67" onClick={() => setMenuIsVisible(false)}/>
            </div>
            <nav className="menu-items">
                <a href="#what-it-does" className="menu-item" onClick={() => navigatgeTo('#what-it-does')}>O que faz</a>
                <a href="#features" className="menu-item" onClick={() => navigatgeTo('#features')}>Caracteristicas</a>
                <a href="#quality" className="menu-item" onClick={() => navigatgeTo('#quality')}>Vantanges</a>
                <a href="#prices" className="menu-item" onClick={() => navigatgeTo('#prices')}>Preço</a>
            </nav>
            <div className="footer-items">
                <button className='contact-us' onClick={onOpenWhatsApp}>
                    <img className='whatsapp-icon' src={Whatsapp} alt="whatsapp"/>
                    <span>Fale Conosco</span>
                </button>
            </div>
        </Container>
    )
}