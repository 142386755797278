import { useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { BrowserRouter } from 'react-router-dom';
import { ToggleProvider } from './shared/hooks/useToggle';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { MenuMobile } from './components/MenuMobile';
import { SendEmailModal } from './components/SendEmailModal';
import { AppRoutes } from './routes';
import { GlobalStyle } from './styles/global';
import Modal from 'react-modal';
import { OpenCloseModalProvider } from './shared/hooks/useOpenCloseModal';

Modal.setAppElement('#root');

function App() {
  const reCaptchaKey =
    process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ||
    '6LdCk84iAAAAAKgB-cD67V_WI-Rrq7F0tO1MgYL-';

  const [menuIsVisible, setMenuIsVisible] = useState(false);

  return (
    <ToggleProvider>
      <OpenCloseModalProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey={reCaptchaKey}
          useRecaptchaNet={true}
        >
          <BrowserRouter>
            <MenuMobile
              menuIsVisible={menuIsVisible}
              setMenuIsVisible={setMenuIsVisible}
            />
            <Header setMenuIsVisible={setMenuIsVisible} />
            <AppRoutes />
            <Footer />
            <GlobalStyle />
          </BrowserRouter>
          <SendEmailModal/>
        </GoogleReCaptchaProvider>
      </OpenCloseModalProvider>
    </ToggleProvider>
  );
}

export default App;
