import styled from 'styled-components';

export const Container = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 42px 1rem 58.32px 1rem;

    overflow: auto;

    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;

        color: #1D3C85;
    }
    
    p {
        margin-top: 24px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;

        text-align: center;

        color: #8B8B8B;
    }

    .form-field {
        width: 91vw;
        max-width: 371px;
        
        border: none;
        padding: 12px 18px 14px 18px;
        background: #F6F9FD;

        resize: none;

        margin-top: 38px;

        & + .form-field {
            margin-top: 15px;
        }
        
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;

        color: #73839C;

        transition: 0.3s;

        &:focus-visible {
            border: none;
            outline: none;
        }
    }

    textarea {
        height: 132px;
    }

    .contact-us {
        width: 100%;
        max-width: 203px;
        height: 100%;
        max-height: 45px;

        margin-top: 23px;

        border: 0;

        background: #FFB805;
        border-radius: 8px;

        display: flex;
        align-items: center;
        gap: 10px;

        padding: 14px 22px 13px 22px;
        
        transition: 0.2s;

        span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;

            color: #1D3C85;
        }

        img {
            width: 24px;
            height: 24px;
        }

        &:hover {
            filter: brightness(1.1);
        }
    }

    /* button {
        padding: 14px 21px;
        margin-top: 23px;
        
        background: #181E67;
        border: none;
        border-radius: 200000px;
        box-shadow: 0px 15px 14px -10px rgba(24, 30, 103, 0.4);

        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;

        color: #FFFFFF;
    } */

    .form-field-error {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        text-align: left;
        width: 91vw;
        max-width: 371px;
        padding-left: 1rem;
        padding-top: 0.5rem;
        color: red;
    }
    .container-selector-input {
        display: flex;
        flex-flow: row;
        background: #F6F9FD;
        border-radius: 5px;
        width: 91vw;

        max-width: 371px;

        .phone-form-field {
            width: 100%;
            background: #F6F9FD;
            border-radius: 5px;

            border: none;
            outline: none;

            padding: 12px 18px 14px 0px ;
        }

        .flag-container {
            display: flex;
            align-items: center;

            padding: 0.5rem;

            .selected-flag {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;

                /* .arrow {
                    width: 22px;
                    height: 22px;
                } */
            }
            .country-list {
                display: none;
                flex-direction: column;
                list-style-type: none;
                width: fit-content;
                height: 20vh;

                overflow-y: scroll;

                position: absolute;
                top: 8rem;
                background-color: #F6F9FD;

                .country {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 10px;
                    gap: 1rem;
                }
            }
        }
    }

    
`