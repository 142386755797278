import styled from 'styled-components';

export const Container = styled.header`
    width: 100%;
    height: 116px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 116px;
    padding: 30px 31.5px;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    background: #FFFFFF;

    .left-content {
        display: flex;

        .logo {
            width: 15rem;
            cursor: pointer;
        }
    }

    .right-content {
        display: flex;
        align-items: center;

        .menu {
            display: flex;
            align-items: center;
        }
        .menu-item {
            position: relative;
            overflow: hidden;
            height: 1.5rem;
            
            transition: 0.3s;

            & + .menu-item {
                margin-left: 55px;
            } 

            &::after {
                position: absolute;
                content: ' ';
                width: 50%;
                height: 3px;

                bottom: 0;
                left: -50%;
                background: #1D3C85;

                transition: 0.3s;
            }

            &:hover {
                &::after {
                    display: block;
                    transform: translateX(150%);
                }
            }

        }

        .menu-link {
            text-decoration: none;

            list-style-type: none;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;

            color: #1D3C85;
            
            transition: 0.3s;

            &:hover {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;

                color: #FFB805;
            }
        }

        .menu-link-active {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;

            color: #FFB805;
        }
        .menu-item-active {
            &::after {
                display: block;
                transform: translateX(150%);
            }
        }

        .contact-us {
            width: 177px;

            display: flex;
            align-items: center;
            gap: 12px;
            
            padding: 13px 24px;
            margin-left: 84px;

            background: #1D3C85;
            border-radius: 8px;
            border: none;

            transition: 0.2s;

            .whatsapp-icon {
                width: 20px;
            }

            span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */
                color: #FFFFFF;
            }

            &:hover {
                filter: brightness(1.2);
            }
        }
    }

    .desktop {
        display: flex;
    }

    .mobile {
        display: none;
    }

    @media (max-width: 1200px) {

        .desktop {
            display: none;
        }

        .hamburguer {
            display: block;
            visibility: visible;
        }
    }
`