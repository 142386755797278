import { Container } from "./styles";
import CheckCircle from '../../../../../assets/images/home/check-circle.png';

interface WhatItDoesProps {
    texts: string[];
}

export function ListItem({ texts }: WhatItDoesProps) {
    return (
        <Container>
            <span className="included">O que está incluso</span>
            <ul className="list">
                {texts.map((text, index) => {
                    return (
                        <li className="list-item" key={'item-' + index}>
                            <img src={CheckCircle} alt="check" className="check" />
                            <span className={`text ${text.startsWith('R$0,') ? 'important-text' : ''}`}>{text}</span>
                        </li>
                    )
                })}
            </ul>
        </Container>
    )
}