import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";

interface OpenCloseModalProviderProps {
    children: ReactNode
}

interface OpenCloseModalContextData {
    plan: string,
    setPlan: Dispatch<SetStateAction<string>>
    isSendEmailModalOpen: boolean;
    handleOpenSendEmailModal: () => void;
    handleCloseSendEmailModal: () => void;
}

const OpenCloseModalContext = createContext<OpenCloseModalContextData>({} as OpenCloseModalContextData);

export function OpenCloseModalProvider({children}: OpenCloseModalProviderProps) {

    const [ isSendEmailModalOpen, setIsSendEmailModalOpen ] = useState(false);
    const [ plan, setPlan ] = useState('');

    function handleOpenSendEmailModal() {
        setIsSendEmailModalOpen(true);
    }

    function handleCloseSendEmailModal() {
        setIsSendEmailModalOpen(false);
    }

    return (
        <OpenCloseModalContext.Provider value={{plan, setPlan, isSendEmailModalOpen, handleOpenSendEmailModal, handleCloseSendEmailModal }}>
            {children}
        </OpenCloseModalContext.Provider>
    )
}

export function useOpenCloseModal() {
    return useContext(OpenCloseModalContext)
}