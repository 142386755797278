import styled from 'styled-components';

export const Container = styled.section`
    width: 100%;
    max-width: 971px;
    height: 746px;

    margin: 0 auto;

    margin-top:  116px;

    display: flex;
    align-items: center;

    .wrapper {
        width: 100%;
        max-width: 416px;
        
        display: flex;
        flex-flow: column;
        gap: 39px;

        padding: 0 1rem;

        .title-content {
            display: flex;
            flex-flow: column;
            gap: 0.6rem;
        }
    }

    .title {
        width: 100%;
        /* max-width: 363px; */

        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;

        color: #1D3C85;
    }

    .highlighted-title {
        width: 100%;
        max-width: 363px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 46px;
        line-height: 32px;

        color: #1D3C85;
    }

    .phrase{
        width: 100%;
        max-width: 395px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #8B8B8B;
    }

    .try-for-free {
        width: 261px;

        padding: 17px 29px;

        display: flex;
        align-items: center;
        gap: 12px;

        background: #1D3C85;
        border-radius: 8px;
        border: 0;

        transition: 0.2s;

        img {
            width: 20px;
        }

        span {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;

            color: #FFFFFF;
        }

        &:hover {
            filter: brightness(1.2);
        }
    }

    .nfe-tool {
        width: 100%;
        max-width: 555px;

        background-size: 555px 534px;
        padding-left: 106px;

        animation: up-down 2s ease-in-out infinite alternate-reverse both;
    }

    @keyframes up-down {
        0% {
            transform: translateY(-20px);
        }
    }


    @media (max-width: 1024px) {
        height: 100%;

        flex-flow: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 53px;

        padding: 53px 0;

        .nfe-tool {
            padding: 0;
        }
    }

    @media (max-width: 667px) { 
        height: 100%;

        flex-flow: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 53px;

        padding: 53px 0;

        .wrapper {
            align-items: center;

            .title {
                font-size: 24px;
                text-align: center;
            }
    
            .highlighted-title {
                font-size: 38px;
                text-align: center;
            }
    
            .phrase {
                max-width: 300px;
                text-align: center;
            }
        }

        .nfe-tool {
            margin: 0;
        }
    }
`