import { FeaturesCards } from "./components/FeaturesCards";
import { FeaturesItems } from "./data/featuresItem";
import { Container } from "./styles";


export function Features() {

    return (
        <Container id="features">
            <div className="wrapper">
                <span className="subtitle">Os diferenciais</span>
                <span className="title">Características</span>
                <p className="phrase">Confira as features que o nosso sistema destaca para você ter o controle total das notas fiscais emitidas para a sua empresa.</p>
                <FeaturesCards featureItem={FeaturesItems} />
            </div>
        </Container>
    )
}