import { useNavigate } from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import { Container } from './styles';

import Logo from '../../assets/images/global/if_logo.png';
import Whatsapp from "../../assets/images/social-media/whatsapp.png";
import { firebaseLogEvent } from '../../services/firebase/firebaseLogEvent';
import { useLayoutEffect, useState } from 'react';
import { clsx } from 'clsx'

interface HeaderProps {
    setMenuIsVisible: React.Dispatch< React.SetStateAction<boolean> >;
}
interface MenuItens {
    id: string;
    name: string;
}

const clamp = (value: number) => Math.max(0, value);

const isBetween = (value: number, floor: number, ceil: number) => value >= floor && value <= ceil;

// hooks
const useScrollspy = (menuItens: MenuItens[], offset: number = 0) => {
    const [activeId, setActiveId] = useState("");

    useLayoutEffect(() => {
        const listener = () => {
            const scroll = window.scrollY;

            const position = menuItens.map(({id}) => {
                const element = document.getElementById(id);

                if (!element) return { id, top: -1, bottom: -1 };

                const rect = element.getBoundingClientRect();
                const top = clamp((rect.top + scroll - 3) - offset);
                const bottom = clamp((rect.bottom + scroll - 3) - offset);

                return { id, top, bottom };
            })
            .find(({ top, bottom }) => isBetween(scroll, top, bottom));

            setActiveId(position?.id || "");
        };

        listener();

        window.addEventListener("resize", listener);
        window.addEventListener("scroll", listener);

        return () => {
            window.removeEventListener("resize", listener);
            window.removeEventListener("scroll", listener);
        };
    }, [menuItens, offset]);

    return activeId;
};

export function Header({ setMenuIsVisible }: HeaderProps) {

    const navigate = useNavigate();

    const onOpenWhatsApp = () => {
        firebaseLogEvent({fbEvent: 'click_whatsapp_menu_desktop'});
        return window.open(`http://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_PHONE_NUMBER}`, '_blank');
    };

    const menuItens = [
        {id: 'home', name: 'Início'},
        {id: 'what-it-does', name: 'O que faz'},
        {id: 'features', name: 'Características'},
        {id: 'quality', name: 'Vantagens'},
        {id: 'prices', name: 'Preço'}
    ];

    const activeId = useScrollspy(menuItens, 116);

    return (
        <Container>
            <div className='left-content'>
                <img className='logo' src={Logo} alt="logo" onClick={() => navigate('/')}/>
            </div>
            <div className='right-content desktop'>
                <ul className='menu'>
                    {menuItens.map( ({ id, name }) => (
                        <li 
                            key={`menu-item-${id}`}
                            className={clsx(
                                "menu-item",
                                id === activeId && "menu-item-active"
                            )}>
                            <a
                                href={`#${id}`}
                                className={clsx(
                                    "menu-link",
                                    id === activeId && "menu-link-active"
                                )}
                            >{name}</a>
                        </li>
                    ))}
                </ul>
                <button className='contact-us' onClick={onOpenWhatsApp}>
                    <img className='whatsapp-icon' src={Whatsapp} alt="whatsapp"/>
                    <span>Fale Conosco</span>
                </button>
            </div>
            
            <div className='hamburguer mobile'>
                <FiMenu size={26} color="#181E67" onClick={() => setMenuIsVisible(true)} />
            </div>
        </Container>
    );
}