import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    .prices-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-flow: row wrap;

        gap: 27.65px;
    }

    .prices-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;

        position: relative;

        width: 100%;
        max-width: 377.17px;

        height: 847px;

        padding: 50px 41.56px 59px 41.56px;

        background: #FFFFFF;
        border: 1px solid #EFF0F7;
        box-shadow: 0px 0px 50px rgba(20, 20, 43, 0.1);
        border-radius: 24px;

        .plan-type {
            width: 100%;

            display: flex;
            flex-flow: row;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 18px;

            .prices-item-image {
                width: 72px;
                height: 72px;
            }

            .plan-type-texts {
                display: flex;
                align-items: flex-start;
                flex-flow: column;
                /* gap: 0rem; */

                .company {
                    font-family: 'DM Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 20px;
                    text-align: left;
                    color: #6F6C90;
                }
    
                .plan {
                    font-family: 'DM Sans';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 35px;

                    color: #170F49;

                    margin-top: -0.15rem;
                }
            }
        }

        .description {
            width: 100%;
            height: 45px;

            margin-top: 28px;
            margin-bottom: 23px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: left;

            color: #6F6C90;

        }

        .price-content {
            width: 100%;

            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-flow: row;
            /* gap: 10px; */

            /* padding-bottom: 1rem; */

            .price {
                width: 100%;
                position: relative;

                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 40px;
                line-height: 66px;

                color: #1D3C85;
            }
            .period {
                position: absolute;
                right: 10px;
                bottom: 0.7rem;

                width: fit-content;
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 22px;

                color: #6F6C90;

            }
            
            @media (max-width: 768px) {
                .price {
                    font-size: 30px;
                }
                .period {
                    font-size: 16px;
                }
            }
        }
        .sign-btn {
            display: flex;
            align-items: center;
            justify-content: center;     

            width: 100%;
            height: 72px;
            min-height: 72px;

            margin-top: auto;
            margin-left: auto;
            margin-right: auto;

            background: #1D3C85;
            border-radius: 8px;
            border: none;


            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 20px;
            text-align: center;

            color: #FFFFFF;

            &:hover {
                filter: brightness(1.2);
            }

            .whatsapp-icon {
                width: 20px;
                margin-right: 0.7rem;
            }
        }
    }

    .prices-item-important {
        height: 970px;
    }

    @media (max-width: 1210px) {
        .prices-item {
            /* height: 887px; */
        }
    }

    @media (max-width: 1024px) {
        .prices-content {
            align-items: center;
            justify-content: center;
            flex-direction: column;
            
            .price-content {
                .price {
                    font-size: 38px;
                }
            }
        }

    }

    @media (max-width: 768px) {
        .prices-item {
            height: 832px;
        }
        .prices-item-important {
            height: 960px;
        }
    }
`