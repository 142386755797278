import styled from 'styled-components';

export const Container = styled.section`
    width: 100%;
    height: 678px;

    padding: 84px  1rem 98px 1rem;

    display: flex;
    align-items: center;

    .wrapper {
        width: 100%;
        max-width: 1080px;

        margin: 0 auto;

        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        text-align: center;

        .subtitle {
            width: 100%;
            max-width: 162px;
            
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 20px;
            
            color: #8B8B8B;
        }

        .title {
            width: 100%;
            max-width: 376px;

            margin-top: 7px;
            margin-bottom: 9px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 54px;

            color: #1D3C85;
        }

        .phrase {
            width: 100%;
            max-width: 485px;

            margin-bottom: 66px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            color: #8B8B8B;
        }
    }

    @media (max-width: 1024px) {
        height: 100%;

        padding: 53px  1rem 53px 1rem;
    }

    @media (max-width: 767px) {
        height: 100%;

        padding: 53px  1rem 13px 1rem;

        .wrapper {
            .title {
                font-size: 42px;
            }
        }
    }
`