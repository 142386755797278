import styled from 'styled-components';

export const Container = styled.section`
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;

    background: rgba(29, 60, 133, 0.05);

    padding: 76px 1rem 68px 1rem;

    .container {
        width: 100%;
        max-width: 1089px;

        display: flex;

        margin: 0 auto;
    }

    .wrapper {
        width: 100%;

        display: flex;
        flex-flow: column;

        .title-content {
            display: flex;
            flex-flow: column;
            gap: 7px;

            .subtitle {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 20px;

                color: #8B8B8B;
            }

            .title {
                width: 100%;
                max-width: 276px;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 50px;
                line-height: 54px;

                color: #1D3C85;
            }
        }
    }


    .phrase-content {
        width: 100%;
        max-width: 399px;

        margin: 15px 0px 39px 0px;

        .phrase {
            width: 100%;
            max-width: 399px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            color: #8B8B8B;

            & + .phrase {
                margin-top: 1.5rem;
            }
        }
    }

    .quality {
        width: 100%;
        max-width: 555px;
        height: 100%;

        margin: auto;
    }

    @media (max-width: 1024px) {
        height: 100%;
        padding: 53px 1rem;

        .container {
            align-items: center;
            flex-direction: column-reverse;
            gap: 53px;

            width: fit-content;
        }

        .wrapper {
            width: fit-content;
        }
    }

    @media (max-width: 767px) {
        .wrapper {
            .title-content {
                align-items: center;

                .title {
                    font-size: 42px;
                    text-align: center;
                }

                .subtitle {
                    text-align: center;
                }
            }
        }
    }
`