import styled from 'styled-components';

export const Container = styled.section`
    width: 100%;
    /* height: 1213px; */
    height: 100%;
    padding: 84px  1rem 98px 1rem;

    .wrapper {
        width: 100%;
        max-width: 1186.81px;

        margin: 0 auto;

        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        text-align: center;

        .subtitle {
            width: 100%;
            max-width: 172px;
            
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 20px;
            
            color: #8B8B8B;
        }

        .title {
            width: 100%;
            max-width: 376px;

            margin-top: 7px;
            margin-bottom: 9px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 54px;

            color: #1D3C85;
        }

        .phrase {
            width: 100%;
            max-width: 658.49px;

            margin-bottom: 66px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            color: #8B8B8B;
        }

        .period-content {
            display: flex;
            align-items: center;
            gap: 8.75px;

            margin-bottom: 75px;

            span {
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 30px;

                color: #170F49;
            }
        }
    }
    @media (max-width: 1180px) {
        /* height: 1300px; */
    }

    @media (max-width: 1024px) {
        height: 100%;
        padding: 53px 1rem;
    }

    @media (max-width: 767px) {
        .wrapper {
            .title {
                font-size: 42px;
            }
        }
    }
`