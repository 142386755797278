import { Customizations } from "../../components/Home/Customizations";
import { Features } from "../../components/Home/Features";
import { Partnership } from "../../components/Home/Partnership";
import { Prices } from "../../components/Home/Prices";
import { Quality } from "../../components/Home/Quality";
import { Summary } from "../../components/Home/Summary";
import { WhatItDoes } from "../../components/Home/WhatItDoes";

export function Home() {
    return (
        <>
            <Summary />
            <WhatItDoes />
            <Features />
            <Quality />
            <Prices />
            <Customizations />
            <Partnership />
        </>
    )
}