import { ListItem } from "../ListItem";
import { Container } from "./styles";

import QualityImage from '../../../assets/images/home/quality.webp';

export function Quality() {

    const listItemTexts = [
        'Solução 100% web.',
        'Design diferenciado para facilitar seu trabalho de qualquer lugar: Computador, tablet ou celular.',
        'Manifestação em lote',
        'Visualize e faça download do DANFE de todas as NFes sendo elas já manifestadas ou não.',
        'Múltiplos usuários com acesso controlado através de senha.',
        'Customização e integração com outras soluções através de nossa API',
        'Cadastro ilimitado de CNPJs.',
        'Não precisa usar o certificado de segurança do CNPJ para acessas as NFe e realizar manifestações',
        'Sistema 100% seguro. Utilizamos serviços Amazon AWS.',
        'Sigilo total de seus dados',
    ];

    return (
        <Container id="quality">
            <div className="container">
                <div className="wrapper">
                    <div className="title-content">
                        <span className="subtitle">Qualidade</span>
                        <span className="title">Vantagens do Sistema</span>
                    </div>
                    <div className="phrase-content">
                        <p className="phrase">O Inova Fiscal é a solução mais moderna construída para monitorar todas as notas fiscais emitidas contra sua empresa de forma bastante ágil, rápida e segura. </p>
                    </div>
                    <ListItem texts={listItemTexts}/>
                </div>
                <img src={QualityImage} alt="nfe system" className="quality" />
            </div>
        </Container>
    )
}