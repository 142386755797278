import styled from 'styled-components';

export const Container = styled.header`
    width: 100%;

    background: #1D3C85;

    .container {
        width: 100%;
        max-width: 1280px;

        margin: 0 auto;

        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 66px 117px;

        gap: 1rem
    }

    .left-content {
        display: flex;
        flex-flow: column;

        .title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 30px;

            color: #FFFFFF;
        }

        .phrase {
            width: 100%;
            max-width: 411px;

            margin-top: 30px;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;

            color: #FFFFFF;

            & + .phrase {
                margin-top: 0.1rem;
            }
        }

        .access-here {
            margin-top: 1rem;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;

            color: #FFFFFF;
        }

        .highlighted-text {
            margin-left: 0.3rem;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;

            color: #FFB805;

            text-decoration: none;
        }

        .logo {
            margin-top: 24px;
            width: 189px;
        }
    }

    .right-content {
        display: flex;
        flex-flow: column;

        .right-content {
            padding-top: 25px;
        }

        .title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 30px;

            color: #FFFFFF;
        }

        .form-group {
            display: flex;
            flex-flow: column;
        }

        .split-field {
            display: flex;
            align-items: center;
            gap: 37px;
            margin-top: 25px;

            .form-field {
                width: 220px;
            }
        }

        .split-area {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 25px;
        }

        .form-field {
            background: none;
            border: none;
            border-bottom: 1px solid #FFFFFF;

            padding: 0.25rem 0.7rem;

            &:focus-visible {
                outline: none;
            }

            &, &::placeholder {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                color: #FFFFFF;
            }
        }

        .textarea-field {
            background: none;
            border: 1px solid #FFFFFF;

            padding: 0.7rem 0.7rem;
            margin-top: 42px;

            height: 77px;
            resize: none;

            &:focus-visible {
                outline: none;
            }

            &, &::placeholder {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;

                color: #FFFFFF;
            }
        }

        .required-field {
            width: 210px;

            margin-left: 0.7rem;

            .required-field-text {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;

                color: #FFFFFF;
            }
        }

        .contact-us {
            width: 100%;
            max-width: 203px;
            height: 100%;
            max-height: 45px;

            border: 0;

            background: #FFB805;
            border-radius: 8px;

            display: flex;
            align-items: center;
            gap: 10px;

            padding: 14px 22px 13px 22px;
            
            transition: 0.2s;

            span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;

                color: #1D3C85;
            }

            img {
                width: 24px;
                height: 24px;
            }

            &:hover {
                filter: brightness(1.1);
            }
        }
    }
    
    @media (max-width: 1024px) {
        .container {
            flex-direction: column-reverse;
            gap: 53px;

            padding: 66px 1rem;

            .left-content {
                .phrase {
                    max-width: 474px;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .container {
            flex-direction: column-reverse;
            gap: 53px;

            padding: 66px 1rem;

            .left-content {
                .phrase {
                    max-width: 474px;
                }
            }

            .right-content {
                width: 100%;

                .split-field {
                    gap: 25px;
                    margin-top: 25px;
                    flex-direction: column;

                    .form-group {
                        width: 100%;
                    }
                    .form-field {
                        width: 100%;
                        input {
                            max-width: 100%;
                        }
                    }
                }

                .required-field {
                    margin-right: 1rem;
                }
            }
        }
    }
`