import { Container } from "./styles";
import Whatsapp from '../../../assets/images/social-media/whatsapp.png';
import NfeTool from '../../../assets/images/home/nfe-tool.webp';
import { firebaseLogEvent } from "../../../services/firebase/firebaseLogEvent";

export function Summary() {

    const onOpenWhatsApp = () => {
        firebaseLogEvent({fbEvent: 'click_whatsapp_summary'});
        return window.open(`http://api.whatsapp.com/send?phone=${process.env.REACT_APP_WHATSAPP_PHONE_NUMBER}`, '_blank');
    };

    return (
        <Container id="home">
            <div className="wrapper">
                <div className="title-content">
                    <h1 className="title"> Descubra a ferramenta </h1>
                    <span className="highlighted-title">mais avançada</span>
                    <h1 className="title">para <span className="highlighted-title">Manifestação</span></h1>
                    <h1 className="title">do Destinatário de NFe</h1>
                </div>
                <p className="phrase">Analise, gerencie e manifeste de uma forma super fácil as notas fiscais que a sua empresa recebe dos seus fornecedores.</p>
                <button className="try-for-free" onClick={onOpenWhatsApp}><img src={Whatsapp} alt="whatsapp" /><span>Experimente Grátis</span></button>
            </div>
            <img src={NfeTool} alt="nfe tool" className="nfe-tool" />
        </Container>
    )
}