import { Container } from "./styles";

import BookmarkCheck from '../../../assets/images/home/bookmark-check.png';

interface WhatItDoesProps {
    texts: string[];
}

export function ListItem({ texts }: WhatItDoesProps) {
    return (
        <Container>
            <ul className="list">
                {texts.map((text, index) => {
                    return (
                        <h3 className="list-item" key={'item-' + index}>
                            <img src={BookmarkCheck} alt="bookmark check" className="bookmark-check" />
                            <span className="text">{text}</span>
                        </h3>
                    )
                })}
            </ul>
        </Container>
    )
}