import { ListItem } from "../ListItem";
import { Container } from "./styles";

import NfeSystem from '../../../assets/images/home/nfe-system.webp';

export function WhatItDoes() {

    const listItemTexts = [
        'Cadastro ilimitado de Matrizes e Filiais',
        'Controle por múltiplos usuários',
        'Busca Simples ou Avançada de Notas Fiscais',
        'Manifestação das Notas Fiscais',
        'Acesso ao DANFE'
    ]
    return (
        <Container id="what-it-does">
            <div className="container">
                <img src={NfeSystem} alt="nfe system" className="nfe-system" />
                <div className="wrapper">
                    <div className="title-content">
                        <span className="subtitle">O que faz?</span>
                        <span className="title">Controle suas Notas Fiscais</span>
                    </div>
                    <div className="phrase-content">
                        <p className="phrase">No dia-a-dia a sua empresa precisa fazer a conferência das notas fiscais emitidas em nome da sua empresa.</p>
                        <p className="phrase">O Inova Fiscal possui uma interface muito simples, fácil e intuitiva para que você identifique e manifeste todas as notas fiscais emitidas contra seus CNPJs.</p>
                    </div>
                    <ListItem texts={listItemTexts}/>
                </div>
            </div>
        </Container>
    )
}