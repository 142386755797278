import { Container } from "./styles";

import { FeaturesItemDto } from "../../../../../shared/interfaces/features-item-dto";

interface FeaturesCardProps {
    featureItem: FeaturesItemDto[];
}

export function FeaturesCards({ featureItem }: FeaturesCardProps) {
    return (
        <Container>
            <ul className="features-content">
                {featureItem.map((item, index) => {
                    return (
                        <li className="features-item" key={'feature-item' + index}>
                            <img src={item.image} alt={`feature ${index}`} className="features-item-image" key={'feature-item-image' + index} />
                            <h3 className="features-item-title" key={'features-item-title' + index}>{item.title}</h3>
                            <span className="features-item-text" key={'features-item-text' + index}>{item.text}</span>
                        </li>
                    )
                })}
            </ul>
        </Container>
    )
}