import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    max-width: 1080px;

    .features-content {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 90px;

        transition: all 0.2s;

        .features-item {
            width: 100%;
            max-width: 300px;

            padding: 32px 40px 30px 40px;

            background: #FFFFFF;
            box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
            border-radius: 16px;

            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;

            transition: all 0.2s;

            .features-item-image {
                width: 100%;
                max-width: 100px;
            }

            .features-item-title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 20px;

                color: #1D3C85;

                margin-top: 18px;
                margin-bottom: 20px;
            }

            .features-item-text {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                /* or 143% */

                text-align: center;

                color: #8B8B8B;
            }
        }
    }

    @media (max-width: 1024px) {
        .features-content {
            flex-direction: column;
            gap: 45px;

            padding-bottom: 40px;
        }
    }
`