import { PricesItemDto } from "../../../../shared/interfaces/prices-item-dto";

import pricesBasic from '../../../../assets/images/home/prices-1.png';
import pricesEnterprise from '../../../../assets/images/home/prices-2.png';


export const pricesItems: PricesItemDto[] = [
    {
        image: pricesBasic,
        company: 'Pequenas empresas',
        plan: 'Basic',
        description: 'Para empresas que estão iniciando.',
        monthlyPrice: 'R$ 69,90',
        yearlyPrice: 'R$ 49,90', 
        includedAnnual: [
            'Até 100 NFe por mês',
            'Sem taxa de Implantação',
            'CNPJs ilimitados',
            'Usuários ilimitados',
            'Operações em Lote de NFe',
            'Consultas avançadas',
            'R$ 0,32 por NFe adicional',
        ],
        includedMonthly: [
            'Até 100 NFe por mês',
            'Sem taxa de Implantação',
            'CNPJs ilimitados',
            'Usuários ilimitados',
            'Operações em Lote de NFe',
            'Consultas avançadas',
            'R$ 0,32 por NFe adicional',
        ],
        buttonName: 'Assinar'
    },
    {
        image: pricesEnterprise,
        company: 'Médias e grandes empresas',
        plan: 'Enterprise',
        description: 'Para empresas em crescimento ou com grande volume de documentos fiscais.',
        monthlyPrice: 'R$ 329,90',
        yearlyPrice: 'R$ 229,90', 
        includedAnnual: [
            'Até 1000 NFe por mês',
            'Sem taxa de Implantação',
            'CNPJs ilimitados',
            'Usuários ilimitados',
            'Operações em Lote de NFe',
            'Consultas avançadas',
            'API de fácil integração',
            'R$0,14 por NFe 1.001 à 10.000',
            'R$0,11 por NFe 10.001 à 20.000',
            'R$0,10 por NFe à partir de 20.001',
        ],
        includedMonthly: [
            'Até 1000 NFe por mês',
            'Sem taxa de Implantação',
            'CNPJs ilimitados',
            'Usuários ilimitados',
            'Operações em Lote de NFe',
            'Consultas avançadas',
            'API de fácil integração',
            'R$0,20 por NFe 1.001 à 10.000',
            'R$0,15 por NFe 10.001 à 20.000',
            'R$0,13 por NFe à partir de 20.001',
        ],
        buttonName: 'Assinar'
    },
    {
        image: pricesEnterprise,
        company: 'Escritórios',
        plan: 'Contabilidade',
        description: 'Plano flexível de acordo com seu negócio.',
        monthlyPrice: 'Consulte!',
        yearlyPrice: 'Consulte!', 
        includedAnnual: [
            'NFe por mês sob medida',
            'Sem taxa de Implantação',
            'CNPJs ilimitados',
            'Usuários ilimitados',
            'Operações em Lote de NFe',
            'Consultas avançadas',
            'API de fácil integração',
        ],
        includedMonthly: [
            'NFe por mês sob medida',
            'Sem taxa de Implantação',
            'CNPJs ilimitados',
            'Usuários ilimitados',
            'Operações em Lote de NFe',
            'Consultas avançadas',
            'API de fácil integração',
        ],
        buttonName: 'Fale Conosco'
    },
];