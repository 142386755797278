import { FeaturesItemDto } from "../../../../shared/interfaces/features-item-dto";

import FeatureTime from '../../../../assets/images/home/feature-time.png';
import FeatureSearch from '../../../../assets/images/home/feature-search.png';
import FeatureChecklist from '../../../../assets/images/home/feature-checklist.png';


export const FeaturesItems: FeaturesItemDto[] = [
    {image: FeatureTime, title: 'Velocidade', text: 'Confira as features que o nosso sistema destaca para você ter o controle total das notas fiscais emitidas para a sua empresa.'},  
    {image: FeatureSearch, title: 'Busca Avançada', text: 'Confira as features que o nosso sistema destaca para você ter o controle total das notas fiscais emitidas para a sua empresa.'}, 
    {image: FeatureChecklist, title: 'Ação em Lotes', text: 'Confira as features que o nosso sistema destaca para você ter o controle total das notas fiscais emitidas para a sua empresa.'}  
];