import React from 'react';
import ReactDOM from 'react-dom/client';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { ToastContainer } from 'react-toastify';
import { firebaseConfig } from './services/firebase/firebaseConfig';
import App from './App';

import 'react-toastify/dist/ReactToastify.css';

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
    <ToastContainer />  
  </React.StrictMode>
);
