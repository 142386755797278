import { useCallback, FormEvent } from "react";
import Modal from 'react-modal'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IoClose } from "react-icons/io5";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { infoNotify, promiseNotify, warningNotify } from '../../services/notifications';
import { ErrorField } from './components/ErrorField';
import { api } from '../../services/api';

import { Container } from './styles';
import ArrowRight from "../../assets/images/global/akar-send.png";
import { useDisableBodyScroll } from "../../shared/helpers/useDisableBodyScroll";
import { useToggle } from "../../shared/hooks/useToggle";
import { useOpenCloseModal } from "../../shared/hooks/useOpenCloseModal";

export function SendEmailModal() {
  const { isSendEmailModalOpen, handleCloseSendEmailModal, plan} = useOpenCloseModal();

  useDisableBodyScroll(isSendEmailModalOpen);

  const { toggled } = useToggle();
  
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {

      if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
      }

      const token = await executeRecaptcha('yourAction');

      return token;

  }, [executeRecaptcha]);

  const handleKeyUp = useCallback((e: FormEvent<HTMLInputElement>) => {
      e.currentTarget.maxLength = 14;
      let value = e.currentTarget.value;
      
      value = value.replace(/\D/g, "");
      value = value.replace(/^(\d{2})(\d)/, '($1) $2'); 
      
      e.currentTarget.value = value;
  }, []);

  const formik = useFormik({
    initialValues: {
      name: '',
      city: '',
      mailer: '',
      message: '',
      subject: `Contato - 10i9` },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(50, 'Deve ter 50 caracteres ou menos')
        .min(2, 'Deve ter pelo menos 2 caracteres')
        .required('Este campo é obrigatório'),
      city: Yup.string()
        .required('Este campo é obrigatório'),
      mailer: Yup.string()
        .min(13, 'Deve ter pelo menos 13 caracteres')
        .required('Este campo é obrigatório'),
      message: Yup.string()
        .max(250, 'Deve ter 250 caracteres ou menos')
        .required('Este campo é obrigatório'),
    }),

    onSubmit: values => {
      values.subject = `${values.subject} - ${plan} ${toggled === true ? 'Anual' : 'Mensal'}`
      formik.validateForm();

      handleReCaptchaVerify()
        .then((res) => {

          if (res && res !== undefined && res !== null) {

            infoNotify('Você não é um robo 🤖!');

            setTimeout(() => {
              promiseNotify(
                api.post('sendmail', values),
                'Enviando e-mail...',
                'E-mail enviado com sucesso !',
                'Envio de e-mail falhou !'
              );

              formik.resetForm();
            }, 6000);

          }
        }).catch((err) => warningNotify('reCaptcha inválido.'));
    },
  });

  function handleModalClose() {
    formik.resetForm();
    handleCloseSendEmailModal();
  }

  // noScroll(isOpen);

  return (
    <Modal 
      isOpen={isSendEmailModalOpen}
      onRequestClose={() => handleModalClose()}
      overlayClassName="send-email-modal-overlay"
      className="send-email-modal-content"
    >
      <IoClose size={25} color="#73839C" onClick={() => handleModalClose()} className='send-email-modal-close' />
      <Container onSubmit={formik.handleSubmit}>
        <h1>{'Assinar'}</h1>
        <p>{'Preencha as informações para entrarmos em contato'}</p>
        <input
          className='form-field'
          placeholder={'Nome'}
          id="name"
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
        />
        <ErrorField
          touchedField={formik.touched.name}
          errorField={formik.errors.name}
        ></ErrorField>
        <input
          className='form-field'
          placeholder={'Cidade/Estado'}
          id="city"
          name="city"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.city}
          style={{marginTop: '11px'}}
        />
        <ErrorField
          touchedField={formik.touched.city}
          errorField={formik.errors.city}
        ></ErrorField>
        <input
          className='form-field'
          placeholder={'DDD + Telefone'}
          type="tel"
          id="mailer"
          name="mailer"
          onKeyUp={handleKeyUp}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.mailer}
          style={{marginTop: '11px'}}
        />
        <ErrorField
          touchedField={formik.touched.mailer}
          errorField={formik.errors.mailer}
        ></ErrorField>
        <textarea
          className='form-field'
          placeholder='Mensagem'
          id="message"
          name="message"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.message}
          style={{marginTop: '11px'}}
        />
        <ErrorField
          touchedField={formik.touched.message}
          errorField={formik.errors.message}
        ></ErrorField>
        <button className='contact-us' type='submit'>
            <img className='arrow-right' src={ArrowRight} alt="arrow right"/>
            <span>Enviar mensagem</span>
        </button>
      </Container>
    </Modal>
  );
}