import { ListItem } from "../ListItem";
import { Container } from "./styles";

import Accounting from '../../../assets/images/home/accounting.webp';

export function Partnership() {

    const listItemTexts = [
        'Integração com APIs',
        'Customização de Layout',
        'Conexão com Receita',
        'Interações com Sistemas'
    ];

    return (
        <Container>
            <div className="container">
                <div className="wrapper">
                    <div className="title-content">
                        <span className="subtitle">Parceria</span>
                        <span className="title">Escritório de Contabilidade</span>
                    </div>
                    <div className="phrase-content">
                        <p className="phrase">A 10i9 faz parceria com escritórios de contabilidade. Com um sistema totalmente personalizado para cada cliente do escritório, você pode oferecer este serviço com uma plataforma desenvolvida do jeito que você precisa, direcionado para cada cliente.</p>
                        <p className="phrase">Entre em contato com nossos consultores e veja as vantagens que podemos proporcionar para você.</p>
                    </div>
                    <ListItem texts={listItemTexts}/>
                </div>
                <img src={Accounting} alt="accounting" className="accounting"/>
            </div>
        </Container>
    )
}