import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    flex-flow: column;

    margin-bottom: 2.6rem;

    .included {
        width: 100%;

        margin-top: 17px;
        margin-bottom: 24px;
        
        text-align: left;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;

        color: #170F49;
    }

    .list-item {
        display: flex;
        align-items: center;
        gap: 10px;

        .check {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 26px;
            height: 26px;
        }
        .text {
            font-family: 'DM Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 20px;

            color: #170F49;
            text-align: left;
        }

        .important-text {
            font-size: 15px;
            font-weight: 500;
        }

        & + .list-item {
            margin-top: 16px;
        }
    }
`