import styled from 'styled-components';

export const Container = styled.section`
    background: rgba(29, 60, 133, 0.05);

    .container {
        width: 100%;
        /* max-width: 1280px; */
        margin: 0 auto;

        display: flex;
        align-items: center;
    }

    .wrapper {
        width: 50%;

        padding: 1rem;
        padding-left: 94px;

        display: flex;
        flex-flow: column;

        .title-content {
            display: flex;
            flex-flow: column;
            gap: 7px;

            .subtitle {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 20px;

                color: #8B8B8B;
            }

            .title {
                width: 100%;
                max-width: 340px;

                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 50px;
                line-height: 54px;

                color: #1D3C85;
            }
        }
    }


    .phrase-content {
        width: 100%;
        max-width: 448px;

        margin: 15px 0px 39px 0px;

        .phrase {
            width: 100%;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            color: #8B8B8B;

            & + .phrase {
                margin-top: 1.5rem;
            }
        }
    }

    .custom {
        width: 100%;
        max-width: 50%;
    }

    @media (max-width: 1128px) {
        .container {
            flex-direction: column;
            gap: 53px;

            padding-bottom: 53px;

            .wrapper {
                padding: 0 1rem;
                max-width: fit-content;
            }
        }

        .custom {
            max-width: 100%;
        }
    }

    @media (max-width: 767px) {
        .wrapper {
            width: 100%;

            .title-content {
                align-items: center;

                .title {
                    font-size: 42px;
                    text-align: center;
                }

                .subtitle {
                    text-align: center;
                }
            }
        }
    }
`