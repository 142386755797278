import styled from 'styled-components';

export const Container = styled.div`
    .form-field-error {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        text-align: left;
        width: 91vw;
        max-width: 371px;
        padding-left: 1rem;
        padding-top: 0.5rem;
        color: red;
    }
`