import { useTranslation } from 'react-i18next';

import { Container } from './styles';

interface ErrorFieldProps {
  touchedField?: any;
  errorField: any;
}
export function ErrorField({ touchedField, errorField }: ErrorFieldProps) {

  const {t} = useTranslation();

  return (
      <Container>
        { touchedField && errorField ? ( <div className='form-field-error'>{t(errorField)}</div> ) : null }
      </Container>
  );
}