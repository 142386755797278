import { ListItem } from "../ListItem";
import { Container } from "./styles";

import Custom from '../../../assets/images/home/custom.webp';

export function Customizations() {

    const listItemTexts = [
        'Integração com APIs',
        'Integração com ERPs',
        'Integração com SASS',
        'Design Personalizado'
    ];

    return (
        <Container>
            <div className="container">
                <img src={Custom} alt="nfe system" className="custom"/>
                <div className="wrapper">
                    <div className="title-content">
                        <span className="subtitle">Feito para você</span>
                        <span className="title">Customização Personalizada</span>
                    </div>
                    <div className="phrase-content">
                        <p className="phrase">A 10i9 abre portas para cada cliente customizar o sistema como ele desejar. Podemos integrar com as mais diversas APIs existentes do mercado, tudo isso para facilitar a vida do seu time no dia-a-dia!</p>
                        <p className="phrase">Entre em contato com nossos consultores e faça um orçamento sem compromisso para customizar o seu sistema de notas fiscais!</p>
                    </div>
                    <ListItem texts={listItemTexts}/>
                </div>
            </div>
        </Container>
    )
}