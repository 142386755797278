import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";

interface ToggleProviderProps {
    children: ReactNode
}

interface ToggleContextData {
    toggled: boolean,
    setToggled: Dispatch<SetStateAction<boolean>>
}

const ToggleContext = createContext<ToggleContextData>({} as ToggleContextData);

export function ToggleProvider({children}: ToggleProviderProps) {

    const [toggled, setToggled] = useState(true);

    return (
        <ToggleContext.Provider value={{toggled, setToggled}}>
            {children}
        </ToggleContext.Provider>
    )
}

export function useToggle() {
    return useContext(ToggleContext)
}